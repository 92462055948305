import React from 'react';
import styled, { css } from 'styled-components';

// size of a tip border
const ARROW_SIZE = '7px';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  &:hover {
    div:nth-last-child(2) {
      visibility: visible;
    }
    div:last-child {
      visibility: visible;
    }
  }
`;

const TooltipArrow = styled.div`
  position: absolute;
  border: solid transparent;
  border-width: ${ARROW_SIZE};
  z-index: 201;
  visibility: hidden;

  ${({ direction }) => {
    if (direction === 'top')
      return css`
        left: 50%;
        top: 0;
        transform: translateX(-50%) translateY(-50%);
        border-top-color: ${({ backgroundColor }) => backgroundColor};
      `;
    if (direction === 'right')
      return css`
        right: 0;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        border-right-color: ${({ backgroundColor }) => backgroundColor};
      `;
    if (direction === 'bottom')
      return css`
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) translateY(50%);
        border-bottom-color: ${({ backgroundColor }) => backgroundColor};
      `;
    if (direction === 'left')
      return css`
        left: 0;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-left-color: ${({ backgroundColor }) => backgroundColor};
      `;
  }}
`;

const TooltipContent = styled.div`
  position: absolute;
  width: ${({ width }) => width};
  border-radius: 7px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 15px 24px;
  font-family: ${({ theme }) => theme?.fonts?.default};
  font-size: 16px;
  font-weight: 500;
  color: ${({ color }) => color};
  line-height: 1.44;
  overflow-wrap: break-word;
  white-space: normal;
  z-index: 200;
  visibility: hidden;

  ${({ direction, offset }) => {
    if (direction === 'top')
      return css`
        left: ${`calc(50% + ${offset})`};
        top: ${`-${ARROW_SIZE}`};
        transform: translateX(-50%) translateY(-100%);
      `;
    if (direction === 'right')
      return css`
        right: ${`-${ARROW_SIZE}`};
        top: ${`calc(50% + ${offset})`};
        transform: translateX(100%) translateY(-50%);
      `;
    if (direction === 'bottom')
      return css`
        left: ${`calc(50% + ${offset})`};
        bottom: ${`-${ARROW_SIZE}`};
        transform: translateX(-50%) translateY(100%);
      `;
    if (direction === 'left')
      return css`
        left: ${`-${ARROW_SIZE}`};
        top: ${`calc(50% + ${offset})`};
        transform: translateX(-100%) translateY(-50%);
      `;
  }}
`;

const Tooltip = ({
  content,
  width = '300px',
  direction = 'bottom',
  offset = '0px',
  backgroundColor = '#3b3b4d',
  color = '#fff',
  children,
}) => (
  <Wrapper>
    {children}
    <TooltipArrow direction={direction} backgroundColor={backgroundColor} />
    <TooltipContent
      direction={direction}
      offset={offset}
      width={width}
      backgroundColor={backgroundColor}
      color={color}
    >
      {content}
    </TooltipContent>
  </Wrapper>
);

export default Tooltip;
